import * as React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {Alert, TextFieldProps} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {DateTimePicker} from '@mui/x-date-pickers';
import TextField from "@mui/material/TextField";
import {merge} from "lodash";
import dayjs from "dayjs";

export type FormInputDateTimeProps = Omit<TextFieldProps, 'name'> & {
   name: string;
   label: string;
   errorMessage?: string;
   ampm?: boolean; // Option to toggle 12/24 hour format
}

export const FormInputDateTime = ({
                                     name,
                                     label,
                                     errorMessage,
                                     ampm = false, // Changed default to false for 24h format
                                     ...rest
                                  }: FormInputDateTimeProps) => {
   const { style } = rest;
   const classes = useStyles();

   const methods = useFormContext();
   const { formState: { errors }, control } = methods;
   const showError = !!errors[name];

   const newStyle = merge(style, {
      border: showError ? '1px solid #f00000' : '1px solid #d0d0d0',
      marginBottom: '8px'
   });

   return (
      <div className={classes.noBorderRadius}>
         <Controller
            name={name}
            control={control}
            defaultValue={null}
            render={({
                        field: { onChange, value },
                        fieldState: { error, invalid }
                     }) => (
               <DateTimePicker
                  label={label}
                  value={value ? dayjs(value) : null}
                  ampm={ampm}
                  showToolbar={true}
                  minutesStep={5}
                  inputFormat={"dd/MM/yyyy HH:mm"}
                  onChange={(newValue) => {
                     if (!newValue) {
                        onChange(null);
                     } else {
                        onChange(dayjs(newValue).format("YYYY-MM-DD HH:mm:ss"))
                     }
                  }}

                  renderInput={(params) => {
                     const { InputProps } = params;

                     const newProps = merge(InputProps, {
                        disableUnderline: true,
                        style: { backgroundColor: 'transparent' }
                     });

                     return (
                        <TextField
                           {...params}
                           helperText={invalid ? errorMessage : null}
                           id={name}
                           size="small"
                           variant="filled"
                           margin="none"
                           fullWidth
                           InputLabelProps={{
                              shrink: true,
                           }}
                           color="primary"
                           InputProps={newProps}
                           error={invalid}
                           style={newStyle}
                        />
                     );
                  }}
               />
            )}
         />
         {showError && <Alert variant="filled" severity="error">{errors[name]?.message}</Alert>}
      </div>
   );
};

const useStyles = makeStyles({
   noBorderRadius: {
      "& fieldset": {
         borderRadius: 0,
      },
      width: '100%'
   }
});