import {createGraphiQLFetcher} from '@graphiql/toolkit';
import {GraphiQL} from 'graphiql';
import React from 'react';
import 'graphiql/graphiql.css';
import {Box} from "@mui/material";


export const GraphiqlPage = () => {
   const reactAppServer = process.env.REACT_APP_APISERVER || 'https://ourimpacthub-graphql.azurewebsites.net'
   const accessToken = sessionStorage.getItem('accessToken');

   const fetcher = createGraphiQLFetcher({
      url: reactAppServer + '/api/graphql', headers: {
         authorization: accessToken||''
      }
   });

   return <Box sx={{ height: '100vh', width: '100vw' }}>
      <GraphiQL fetcher={fetcher}/>
   </Box>
}