import {
   EnumIntegrationConnectorMessages,
   IntegrationConnectorFields,
   RefIntegrationSettingsDocument,
   useRefIntegrationMessageUpdateMutation,
   useRefIntegrationSettingsQuery,
   useRefIntegrationSettingUpdateMutation
} from "../../../Queries";
import {SavingUI} from "../../../SavingProvider";
import {Box, LinearProgress} from "@mui/material";
import PageContent from "../../../layout/PageContent";
import {BodyText, TitleText} from "../../../layout/Typography";
import * as React from "react";
import PageContentTabs, {PageTab} from "../../../layout/PageContentTabs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {SystemMessages} from "./RefSystemMessages";
import {SettingsEditor} from "./RefIntegrationSettingsEditor";
import {SettingsLine} from "./RefIntegrationSettingsType";

type RefIntegrationSettingsProps = {
   instanceId: string
   settingsMode?: boolean
}

export const RefIntegrationSettings = ({settingsMode, instanceId}: RefIntegrationSettingsProps) => {
   const {data} = useRefIntegrationSettingsQuery({
      variables: {
         instanceId
      }
   });
   const [save] = useRefIntegrationSettingUpdateMutation()

   const onSave = async (settings: SettingsLine[]) => {
      await SavingUI.process(async () => {
         await save({
            variables: {
               instanceId,
               input: settings.map(setting => ({
                  key: setting.key,
                  value: setting.value
               }))
            },
            refetchQueries: [{
               query: RefIntegrationSettingsDocument,
               variables: {
                  instanceId
               }
            }]
         })
      }, 'Saving settings to connector')
   }

   if (!data) {
      return <LinearProgress/>
   }

   const tabs: PageTab[] = [{
      id: 'Messages',
      label: data?.IntegrationInstance?.name || 'Messages',
   }, {
      label: 'Settings'
   }]

   const Editor = () => <SettingsEditor onSave={onSave}
                                  fields={(data?.IntegrationInstance?.connector?.fields || []) as IntegrationConnectorFields[]}
                                  settings={data?.IntegrationInstance?.settings?.map(setting => {
                                     return {
                                        key: setting!.key,
                                        value: setting!.value
                                     }
                                  }) || []}/>


   return <div>
      {settingsMode ? <Editor/> : <PageContentTabs onBack tabs={tabs} render={(tab) => {
         if (tab === 'Messages') {
            return <PageContent>
               <MessagesEditor instanceId={instanceId}/>
            </PageContent>
         } else if (tab === 'Settings') {
            return <PageContent>
               <Editor/>
            </PageContent>
         } else {
            return <Box></Box>
         }
      }
      }/>}
   </div>
}


type MessagesEditorProps = {
   instanceId: string
}

const MessagesEditor = ({instanceId}: MessagesEditorProps) => {
   const {data} = useRefIntegrationSettingsQuery({
      variables: {
         instanceId
      }
   });
   const [update] = useRefIntegrationMessageUpdateMutation()

   if (!data) {
      return <LinearProgress/>
   }

   const onToggleMessage = (message: EnumIntegrationConnectorMessages) => async (e: any, checked: boolean) => {
      await update({
         variables: {
            instanceId,
            message: message as any,
            enabled: checked
         },
         refetchQueries: [{
            query: RefIntegrationSettingsDocument,
            variables: {
               instanceId
            }
         }]
      })
   }

   return <Box>
      <TitleText type={"h2"}>API Messages supported</TitleText>
      <BodyText type={"body1"}>The {data?.IntegrationInstance?.name} integration supports the following messages. You
         can enable/disable messages to cutomize the integration.</BodyText>
      {data.IntegrationInstance?.connector?.messages?.map(message => {
         const config = data.IntegrationInstance?.configs?.find(config => config?.message as string === message as string);
         return <Box key={instanceId + '_' + message}>
            <FormControlLabel
               disabled={SystemMessages.includes(message as EnumIntegrationConnectorMessages)}
               control={<Checkbox color="primary"/>}
               checked={config?.enabled}
               label={message}
               onChange={onToggleMessage(message!)}
            />
         </Box>
      })}
   </Box>
}