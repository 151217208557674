import React, {useEffect} from 'react';
import {adaptV4Theme, createTheme, CssBaseline, StyledEngineProvider, ThemeProvider,} from "@mui/material";
import {RecoilRoot, useRecoilState, useSetRecoilState} from "recoil";
import {DialogProvider} from "./DialogProvider";
import {BrowserRouter, Navigate, Route, Routes, useNavigate, useParams} from "react-router-dom";
import {ApolloApp} from "./ApolloApp";
import {CurrentUserState, QrSessionInfo, saveToStorage, UserType} from "./atoms/CurrentUser";
import {LocalizationProvider} from '@mui/x-date-pickers';
import '@mui/lab';
import {AdapterDateFns as DateFnsAdapter} from '@mui/x-date-pickers/AdapterDateFns';

import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear"
import relativeTime from "dayjs/plugin/relativeTime"
import {SavingProvider} from './SavingProvider';
import {parse} from "query-string";
import {GraphiqlPage} from "./pages/admin/GraphiqlPage";

dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(relativeTime);

function App() {
   // The padding is due to : https://material-ui.com/components/grid/#limitations
   return (
      <StyledEngineProvider injectFirst>
         <ThemeProvider theme={theme}>
            <LocalizationProvider
               dateAdapter={DateFnsAdapter}
            >
               <div>
                  <CssBaseline/>
                  <RecoilRoot>
                     <BrowserRouter basename={"/"}>
                        <DialogProvider>
                           <SavingProvider>
                              <AutoLoginApp/>
                           </SavingProvider>
                        </DialogProvider>
                     </BrowserRouter>
                  </RecoilRoot>
               </div>
            </LocalizationProvider>
         </ThemeProvider>
      </StyledEngineProvider>
   );
}

export const AutoLoginApp = () => {
   const setCurrentUser = useSetRecoilState(CurrentUserState);

   const parsedQuery = parse(window.location.search);
   const token = parsedQuery.login as string;

   useEffect(() => {
      if (token) {
         const tokenData = parseJwt(token);
         const newUser = {
            token: token,
            brandhouse: true,
            brandHouseId: tokenData.brandHouseId,
            shopId: tokenData.shopId,
            userType: 'brandhouse' as UserType,
            userEmail: 'Brand'
         };
         setCurrentUser(newUser);
         saveToStorage(newUser);

         window.history.replaceState(null, '', window.location.pathname)
      }
   }, [setCurrentUser, token]);

   return <Routes>
      <Route path={"/admin/graphql"} element={<GraphiqlPage/>}/>
      <Route path={"login/:token/:session"} element={<AutoLoginQRSession/>}/>
      <Route path={"login/:token"} element={<AutoLoginBrandHouse/>}/>
      <Route path={"redirect/:token/*"} element={<AutoLoginBrandHouse/>}/>
      <Route path={"*"} element={<ApolloApp/>}/>
   </Routes>
}

export function parseJwt(token: string) {
   var base64Url = token.split('.')[1];
   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
   var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
   }).join(''));
   return JSON.parse(jsonPayload);
}

const AutoLoginBrandHouse = () => {
   const {token, "*": page} = useParams<{ token: string, "*"?: string }>()
   const [currentUser, setCurrentUser] = useRecoilState(CurrentUserState);

   useEffect(() => {
      const tokenData = parseJwt(token);

      const newUser = {
         token: token,
         brandhouse: true,
         brandHouseId: tokenData.brandHouseId,
         shopId: tokenData.shopId,
         userType: 'brandhouse' as UserType,
         userEmail: 'Brand'
      };
      setCurrentUser(newUser);
      saveToStorage(newUser);
   }, [setCurrentUser, token])

   if (page) {
      return <Navigate to={"/" + page}/>
   } else if (currentUser) {
      return <Navigate to={"/brands/" + currentUser.brandHouseId + "/" + currentUser.shopId}/>
   } else {
      return <div>Please wait while logging in...</div>
   }
}

const AutoLoginQRSession = () => {
   const {token, session} = useParams<{ token: string, session: string }>()
   const history = useNavigate();
   const [currentUser, setCurrentUser] = useRecoilState(CurrentUserState);

   useEffect(() => {
      let newUser = {
         token: token,
         brandhouse: false,
         hideFrame: true,
         qrSession: {
            id: session,
            state: 'sender'
         } as QrSessionInfo,
         userType: 'user' as UserType
      };
      setCurrentUser(newUser);
      saveToStorage(newUser);
   }, [history, session, setCurrentUser, token])

   if (currentUser) {
      return <Navigate to={"/qrsession"}/>
   } else {
      return <div>Please wait while logging in...</div>
   }
}


export const theme = createTheme(adaptV4Theme({
   props: {
      MuiButton: {
         disableElevation: true
      },
      MuiPaper: {
         variant: "outlined"
      }
   },
   overrides: {
      MuiButton: {
         text: {
            textTransform: "none",
            fontWeight: 600
         }
      },
      MuiDialogTitle: {
         root: {
            color: '#000000',
            fontWeight: 800
         }
      },
      MuiDialogContent: {
         root: {
            color: '#606060'
         }
      },
      MuiAccordion: {
         root: {
            borderRadius: '4px',
         }
      },
      MuiPaper: {
         root: {
            borderRadius: '4px !important'
         }
      },
      MuiChip: {
         root: {
            paddingTop: '15px',
            paddingBottom: '15px',
            paddingLeft: '5px',
            paddingRight: '5px',
            borderRadius: '8px'
         }
      }
   },
   typography: {
      fontFamily: 'Roboto, sans-serif',
      h1: {
         letterSpacing: '0.3px',
         fontSize: '20px',
         color: '#606060',
         fontWeight: 600
      },
      h2: {
         letterSpacing: '0.24px',
         fontSize: '16px',
         fontWeight: 500,
         color: '#606060',
         marginBottom: '5px',
         marginTop: '20px'
      },
      h3: {
         letterSpacing: '0.16px',
         fontSize: '14px',
      },
      h4: {
         fontSize: '14px',
      },
      body1: {
         fontSize: '14px'
      },
      body2: {},
      subtitle1: {
         color: '#909090'
      },
      subtitle2: {
         color: '#909090',
         fontWeight: 'normal'
      }
   },
   palette: {
      secondary: {
         main: "#808080",
         contrastText: "#FFFFFF"
      },
      primary: {main: "#FF5376"},
      success: {
         main: "#a5d6a7",
         contrastText: "#FFFFFF"
      }
   }
}));

export default App;
