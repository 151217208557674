import * as React from "react";
import {useEffect, useState} from "react";
import {CircularProgress, Dialog, DialogContent, Snackbar} from "@mui/material";
import {PubSub} from "./components/PubSub";
import {CloseIcon} from "./components/Icons";
import {Error} from "@mui/icons-material";
import {BodyText} from "./layout/Typography";

type SavingProviderProps = {
   children: React.ReactNode | React.ReactNode[] | null,
}

const process = async (execute: () => Promise<any>, completeMessage: string, progressText?: string) => {
   PubSub.emit('SavingProvider_start', {modal: progressText})
   try {
      const result = await execute();
      PubSub.emit('SavingProvider_end', {
         message: completeMessage
      })
      return result;
   } catch (e) {
      PubSub.emit('SavingProvider_error', {
         failure: true
      })
   }
}

export const SavingUI = {
   process
}

type SavingDetails = {
   open: boolean
   saving: boolean
   message?: string
   failure?: boolean
   modal?: string
}

export const SavingProvider = ({children}: SavingProviderProps) => {
   const [details, setDetails] = useState<SavingDetails | undefined>();

   useEffect(() => {
      const unregisterStart = PubSub.on('SavingProvider_start', (args) => {
         setDetails({
            open: true,
            saving: true,
            modal: args?.modal
         });
      })
      const unregisterEnd = PubSub.on('SavingProvider_end', (args: any) => {
         setDetails({
            open: true,
            saving: false,
            message: args.message
         });
      });
      const unregisterError = PubSub.on('SavingProvider_error', (args: any) => {
         setDetails({
            open: true,
            saving: false,
            failure: true
         });
      });
      return () => {
         unregisterStart();
         unregisterEnd();
         unregisterError();
      }
   }, [])

   const onClose = () => {
      setDetails({
         open: false,
         saving: false
      });
   }

   let actionComponent = details?.saving ? <CircularProgress size={"20px"}/> : <CloseIcon iconcolor={'white'}/>;
   let actionMessage = details?.message || 'Please wait..';

   if (details?.failure) {
      actionComponent = <Error htmlColor={"#C0C000"}/>
      actionMessage = 'Error occured while saving'
   }

   if (details?.modal) {
      return <>
         <Dialog open={details?.open} onClose={onClose}>
            <DialogContent sx={{textAlign: 'center'}}>
               <CircularProgress size={"100px"}/>
               <BodyText type={"body1"}>{details?.modal}</BodyText>
            </DialogContent>
         </Dialog>
         {children}
      </>
   } else {
      return <>
         <Snackbar open={details?.open} autoHideDuration={10000} onClose={onClose} anchorOrigin={{
            horizontal: "center",
            vertical: "bottom"
         }} action={actionComponent} message={actionMessage}/>
         {children}
      </>
   }
}