import React, {useState} from 'react';
import {
   Box,
   Button,
   Card,
   CardActionArea,
   CardContent,
   Chip,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   IconButton,
   Tooltip,
   Typography
} from '@mui/material';
import {FormProvider, useForm} from 'react-hook-form';
import {
   CampaignFragment,
   CampaignsDocument,
   CampaignsQuery,
   CreateOneCampaignInput,
   useCampaignCreateMutation,
   useCampaignsQuery,
   useCampaignUpdateMutation
} from "../../Queries";
import {FormInputText} from "../shop/wholesale/form/FormInputText";
import {FormInputPercent} from "../shop/wholesale/form/FormInputPercent";
import {FormInputCheckbox} from "../shop/wholesale/form/FormInputCheckbox";
import {AddCircleOutline, CheckCircle, ExpandMore, History} from "@mui/icons-material";
import dayjs from 'dayjs';
import {FormInputDateTime} from "../shop/wholesale/form/FormInputDateTime";

type CampaignPricingProps = {
   brandHouseId: string;
   open: boolean;
   onClose: () => void;
}

type CampaignFormType = {
   enabled: boolean;
   fromDate: Date | null;
   toDate: Date | null;
   percentage: number | null;
   campaignName: string | null;
}

export const CampaignPricing = ({brandHouseId, open, onClose}: CampaignPricingProps) => {
   const {data, client} = useCampaignsQuery({
      variables: {
         brandHouseId
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
   });

   const [campaignCreate] = useCampaignCreateMutation({
      update: (cache, {data: mutationData}) => {
         const newCampaign = mutationData?.CampaignCreate?.record;
         if (!newCampaign) return;

         const existingData = cache.readQuery<CampaignsQuery>({
            query: CampaignsDocument,
            variables: {brandHouseId}
         });

         if (!existingData) return;

         cache.writeQuery({
            query: CampaignsDocument,
            variables: {brandHouseId},
            data: {
               ...existingData,
               Campaigns: [...existingData.Campaigns, newCampaign]
            }
         });
      }
   });

   const [campaignUpdate] = useCampaignUpdateMutation({
      update: (cache, {data: mutationData}) => {
         const updatedCampaign = mutationData?.CampaignUpdate?.record;
         if (!updatedCampaign) return;

         const existingData = cache.readQuery<CampaignsQuery>({
            query: CampaignsDocument,
            variables: {brandHouseId}
         });

         if (!existingData) return;

         cache.writeQuery({
            query: CampaignsDocument,
            variables: {brandHouseId},
            data: {
               ...existingData,
               Campaigns: existingData.Campaigns.map(campaign =>
                  campaign._id === updatedCampaign._id ? updatedCampaign : campaign
               )
            }
         });
      }
   });

   const [isEditMode, setIsEditMode] = useState(false);
   const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(null);

   const formatDateTime = (timestamp: string | Date) => {
      return dayjs.utc(timestamp).local().format('DD-MMM-YYYY HH:mm');
   };

   // Updated to include completed campaigns from the last 30 days
   const isRelevantCampaign = (campaign: CampaignFragment) => {
      const endDate = dayjs(campaign.toDate);
      const thirtyDaysAgo = dayjs().subtract(30, 'days');

      if (campaign.completed) {
         return dayjs(campaign.endedTs).isAfter(thirtyDaysAgo);
      }
      return endDate.isAfter(dayjs(), 'day') || campaign.active;
   };

   if (!data) return null;

   const campaigns = (data.Campaigns || [])
      .filter(isRelevantCampaign)
      .sort((a, b) => dayjs(a.fromDate).valueOf() - dayjs(b.fromDate).valueOf());

   const selectedCampaign = campaigns.find(c => c._id === selectedCampaignId);

   const handleEditCampaign = (campaign: CampaignFragment) => {
      if (campaign.completed) {
         return; // Prevent editing completed campaigns
      }
      setSelectedCampaignId(campaign._id);
      setIsEditMode(true);
   };

   const handleSubmit = async (formData: CampaignFormType) => {
      try {
         const campaignData = {
            brandHouseId,
            campaignName: formData.campaignName,
            fromDate: formData.fromDate,
            toDate: formData.toDate,
            percentage: formData.percentage! * 100,
            disabled: !formData.enabled
         };

         if (selectedCampaign) {
            await campaignUpdate({
               variables: {
                  _id: selectedCampaign._id,
                  input: campaignData
               }
            });
         } else if (campaignData.campaignName && campaignData.fromDate && campaignData.toDate && campaignData.percentage) {
            const result = await campaignCreate({
               variables: {
                  input: campaignData as CreateOneCampaignInput
               }
            });
            if (result.data?.CampaignCreate?.record?._id) {
               setSelectedCampaignId(result.data.CampaignCreate.record._id);
            }
         }

         await client.refetchQueries({
            include: [CampaignsDocument],
         });

         setIsEditMode(false);
         setSelectedCampaignId(null);
      } catch (error) {
         console.error('Error saving campaign:', error);
      }
   };

   const handleNewCampaign = () => {
      setSelectedCampaignId(null);
      setIsEditMode(true);
   };

   const handleBack = () => {
      setIsEditMode(false);
      setSelectedCampaignId(null);
   };

   const getInitialFormData = () => {
      if (!selectedCampaignId) {
         return {
            campaignName: '',
            fromDate: null,
            toDate: null,
            percentage: null,
            enabled: true
         };
      }

      // Convert UTC dates from server to local timezone for form
      const fromDate = selectedCampaign?.fromDate ?
         dayjs.utc(selectedCampaign.fromDate).local().toDate() : null;
      const toDate = selectedCampaign?.toDate ?
         dayjs.utc(selectedCampaign.toDate).local().toDate() : null;

      return {
         campaignName: selectedCampaign?.campaignName || '',
         fromDate,
         toDate,
         percentage: selectedCampaign?.percentage ? selectedCampaign.percentage / 100 : null,
         enabled: !(selectedCampaign?.disabled || false)
      };
   };

   const getCampaignStatusChip = (campaign: CampaignFragment) => {
      if (campaign.completed) {
         return <Chip label="Completed" color="default" size="medium" icon={<CheckCircle/>}/>;
      }
      if (campaign.active) {
         if(campaign.startedTs) {
            return <Chip label="Started" color="success" size="medium"/>;
         } else {
            return <Chip label="Starting soon" color="default" size="medium"/>;
         }
      }
      return <Chip label="Waiting" color="default" size="medium"/>;
   };

   return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
         <DialogTitle className="flex items-center gap-4">
            {isEditMode && (
               <IconButton onClick={handleBack} size="small">
                  <ExpandMore/>
               </IconButton>
            )}
            <span>
               {isEditMode
                  ? (selectedCampaignId ? 'Edit Campaign' : 'Add Campaign')
                  : 'Campaigns'}
            </span>
         </DialogTitle>

         <DialogContent>
            {!isEditMode ? (
               <Box sx={{gap: 1, display: 'flex', flexDirection: 'column'}}>
                  {campaigns.map((campaign) => (
                     <Card
                        key={campaign._id}
                        onClick={() => handleEditCampaign(campaign)}
                     >
                        <CardActionArea sx={{
                           opacity: campaign.completed ? 0.4 : 1,
                           cursor: campaign.completed ? 'default' : 'pointer'
                        }}>
                           <CardContent>
                              <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                 <Box>
                                    <Typography variant="h6">
                                       {campaign.campaignName}
                                       {campaign.completed && (
                                          <Tooltip title="Campaign completed">
                                             <CheckCircle
                                                sx={{
                                                   ml: 1,
                                                   verticalAlign: 'middle',
                                                   fontSize: '1rem',
                                                   color: 'success.main'
                                                }}
                                             />
                                          </Tooltip>
                                       )}
                                    </Typography>
                                    {!campaign.completed && <>
                                    <Typography variant="body2" color="textSecondary">
                                       {formatDateTime(campaign.fromDate)} - {formatDateTime(campaign.toDate)}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                       Discount: {campaign.percentage}%
                                    </Typography>
                                    </>}
                                 </Box>
                                 <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-end'}}>
                                    {getCampaignStatusChip(campaign)}
                                    {!campaign.completed && dayjs(campaign.fromDate).isAfter(dayjs(), 'day') && (
                                       <Typography variant="caption" color="textSecondary">
                                          Starts in {dayjs(campaign.fromDate).diff(dayjs(), 'day')} days
                                       </Typography>
                                    )}
                                    {!campaign.completed && campaign.startedTs && (
                                       <Box sx={{display: 'flex', alignItems: 'center', gap: 0.5, mt: 1}}>
                                          <History sx={{fontSize: '1rem'}} color={"secondary"}/>
                                          <Typography variant="caption" color={"textSecondary"}>
                                             Started {formatDateTime(campaign.startedTs)}
                                          </Typography>
                                       </Box>
                                    )}
                                 </Box>
                              </Box>
                           </CardContent>
                        </CardActionArea>
                     </Card>
                  ))}
                  <Button
                     startIcon={<AddCircleOutline/>}
                     onClick={handleNewCampaign}
                     variant="outlined"
                     fullWidth
                  >
                     Add Campaign
                  </Button>
               </Box>
            ) : (
               <CampaignForm
                  data={getInitialFormData()}
                  onSubmit={handleSubmit}
                  onCancel={handleBack}
               />
            )}
         </DialogContent>

         {!isEditMode && (
            <DialogActions>
               <Button onClick={onClose}>Close</Button>
            </DialogActions>
         )}
      </Dialog>
   );
};

type CampaignFormProps = {
   data: CampaignFormType;
   onCancel: () => void;
   onSubmit: (data: CampaignFormType) => void;
}

const CampaignForm = ({data, onSubmit, onCancel}: CampaignFormProps) => {
   const form = useForm<CampaignFormType>({
      defaultValues: data
   });

   const minDate = dayjs().startOf('day').format('YYYY-MM-DD');

   return (
      <FormProvider {...form}>
         <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-4">
               <FormInputText
                  name="campaignName"
                  label="Campaign Name"
                  required
                  fullWidth
               />

               <div className="grid grid-cols-2 gap-4">
                  <FormInputDateTime
                     name="fromDate"
                     label="Start Date"
                     required
                     inputProps={{
                        min: minDate
                     }}
                  />
                  <FormInputDateTime
                     name="toDate"
                     label="End Date"
                     required
                     inputProps={{
                        min: form.watch('fromDate') || minDate
                     }}
                  />
               </div>

               <FormInputPercent
                  name="percentage"
                  label="Discount Percentage"
                  required
               />
               <FormInputCheckbox
                  name="enabled"
                  label="Activate Campaign"
               />
            </div>

            <DialogActions>
               <Button onClick={onCancel}>Cancel</Button>
               <Button type="submit" variant="contained" color="primary">
                  Save Changes
               </Button>
            </DialogActions>
         </form>
      </FormProvider>
   );
};

export default CampaignPricing;