import * as React from "react";
import {FC} from "react";
import {
   BrandSettingSellPriceAdvancedInput,
   BrandSettingSellPriceInput,
   EnumBrandSettingSellPriceAdvancedType, ItemSourceType,
   SellingPriceSettingsFragment, useSellingPriceSettingsBrandQuery,
   useSellingPriceSettingsQuery,
   useSellingPriceSettingsShopQuery,
   useSellingPriceSettingsUpdateMutation
} from "../../../Queries";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {BodyText, TitleText} from "../../../layout/Typography";
import {Box, Button, Grid, IconButton, LinearProgress, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {FormInputNumber} from "../../shop/wholesale/form/FormInputNumber";
import {AddCircle, DeleteForever} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import {FormInputFree} from "../../shop/wholesale/form/FormInputFree";
import LanguageIcon from '@mui/icons-material/Language';
import {SavingUI} from "../../../SavingProvider";
import {Dirty, DirtyDetector, ReactHookDirtyDetector} from "../../../components/DirtyDetector";
import SettingsHeader from "../SettingsHeader";
import {ShopTabs} from "../ShopTabs";
import PageContent from "../../../layout/PageContent";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";

const FORMID_SELLINGPRICES = "sellingprices";

const SellingPriceSettingsPath = (brandId: string, shopId: string) => {
   return "/brands/" + brandId + "/settings/sellingprices/" + shopId
}

export const SellingPriceSettings = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const navigate = useNavigate();

   const handleChange = (newValue: string) => {
      navigate(SellingPriceSettingsPath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Selling prices"}>
         <Button variant={"contained"} type={"submit"} form={FORMID_SELLINGPRICES}>
            Save
         </Button>
      </SettingsHeader>
      <ShopTabs
         activeId={shopId}
         brandHouseId={brandHouseId}
         onChange={handleChange}
         renderDefault={() => <BrandSellingPriceSettingsInner brandHouseId={brandHouseId}/>}
         renderShop={(shop) => <SellingPriceSettingsInner shopId={shop}/>}
      />
   </div>
}

type SellingPriceSettingsInnerProps = {
   shopId: string
}

const SellingPriceSettingsInner = ({shopId}: SellingPriceSettingsInnerProps) => {
   const {data: staticData} = useSellingPriceSettingsShopQuery({
      variables: {
         shopId: shopId
      }
   });

   const {data: settings} = useSellingPriceSettingsQuery({
      variables: {
         shopId: shopId
      },
      fetchPolicy: "no-cache"
   });

   const [saveSettings] = useSellingPriceSettingsUpdateMutation();

   const onSave = async (data: SellingPriceSettingsFragment) => {
      let index = 0;
      for (const sellPriceElement of data.sellPrice || []) {
         if (!sellPriceElement!.condition) {
            sellPriceElement!.condition = staticData?.ShopById?.conditions?.[index]?.condition || index
         }
         index++;
      }

      await SavingUI.process(async () => {
         await saveSettings({
            variables: {
               shopId: shopId,
               sellPrice: data.sellPrice as BrandSettingSellPriceInput[],
               advanced: data.sellPriceAdvanced as BrandSettingSellPriceAdvancedInput[],
               disableRRPListPrice: data.disableRRPListPrice || false
            }
         });
         Dirty.reset()
      }, 'Stock settings updated')
   }

   if (!staticData || !settings) {
      return <LinearProgress/>
   }

   const data = settings.BrandSettings!;
   if ((data.sellPrice||[]).length===0) {
      data.sellPrice = staticData.ShopById!.conditions?.map(cond => {
         return {
            condition: cond!.condition,
            pct: 0
         }
      });
   }

   console.log(data);

   return <>
      <SellingPriceEditor
         conditions={staticData.ShopById!.conditions! as SellingPriceCondition[]}
         tags={staticData.ShopById!.productTags || []}
         productTypes={staticData.ShopById!.productTypes || []}
         data={data}
         onSave={onSave}
      />
      <DirtyDetector/>
   </>
}


type BrandSellingPriceSettingsInnerProps = {
   brandHouseId: string
}

const BrandSellingPriceSettingsInner = ({brandHouseId}: BrandSellingPriceSettingsInnerProps) => {
   const {data: staticData} = useSellingPriceSettingsBrandQuery({
      variables: {
         brandId: brandHouseId
      }
   });

   const {data: settings} = useSellingPriceSettingsQuery({
      variables: {
         brandHouseId: brandHouseId
      },
      fetchPolicy: "no-cache"
   })

   const [saveSettings] = useSellingPriceSettingsUpdateMutation();

   const onSave = async (data: SellingPriceSettingsFragment) => {
      let index = 0;
      for (const sellPriceElement of data.sellPrice || []) {
         if (!sellPriceElement!.condition) {
            sellPriceElement!.condition = staticData?.BrandHouse?.conditions?.[index]?.condition || index
         }
         index++;
      }

      await SavingUI.process(async () => {
         await saveSettings({
            variables: {
               brandHouseId: brandHouseId,
               sellPrice: data.sellPrice as BrandSettingSellPriceInput[],
               disableRRPListPrice: data.disableRRPListPrice || false
            }
         });
         Dirty.reset()
      }, 'Default settings updated')
   }

   if (!staticData || !settings) {
      return <LinearProgress/>
   }

   const data = settings.BrandSettings!;
   if ((data.sellPrice||[]).length===0) {
      data.sellPrice = staticData.BrandHouse!.conditions?.map(cond => {
         return {
            condition: cond!.condition,
            pct: 0
         }
      });
   }
   return <>
      <SellingPriceEditor
         conditions={staticData.BrandHouse!.conditions! as SellingPriceCondition[]}
         tags={[]}
         productTypes={[]}
         data={data}
         onSave={onSave}
      />
      <DirtyDetector/>
   </>
}

type SellingPriceCondition = {
   condition: number
   name: string
   description: string
}

type SellingPriceEditorProps = {
   conditions: SellingPriceCondition[]
   tags: string[]
   productTypes: string[]
   data: SellingPriceSettingsFragment
   onSave: (data: SellingPriceSettingsFragment) => Promise<void>
}

const SellPriceSchema = yup.object().shape({
   condition: yup.number().required(),
   pct: yup.number().required('Please enter a percentage between 0 and 100')
      .min(0, 'Minimum is 0% (free)')
      .max(100, 'Maximum 100% is supported')
})

const SellPricesSchema = yup.array().of(SellPriceSchema)

const SellPricesAdvancedSchema = yup.array().of(
   yup.object().shape({
      value: yup.string().required('Please select one of the product tags from the list'),
      sellPrice: SellPricesSchema
   })
);

const StockConfigurationSchema = yup.object().shape({
   sellPrice: SellPricesSchema,
   sellPriceAdvanced: SellPricesAdvancedSchema
})

const SellingPriceEditor: FC<SellingPriceEditorProps> = ({
                                                            conditions,
                                                            productTypes,
                                                            tags,
                                                            data,
                                                            onSave
                                                         }) => {
   const [anchorElSp, setAnchorElSp] = React.useState<null | HTMLElement>(null);

   const methods = useForm<SellingPriceSettingsFragment>({
      resolver: yupResolver(StockConfigurationSchema),
      defaultValues: data,
      reValidateMode: "onChange"
   });

   const {getValues, handleSubmit, control} = methods;

   const {fields, append, remove} = useFieldArray({
      control,
      name: 'sellPriceAdvanced'
   })

   const openSp = Boolean(anchorElSp);
   const handleClickSp = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElSp(event.currentTarget);
   };
   const handleCloseSp = () => {
      setAnchorElSp(null);
   };

   return <div>
      <form onSubmit={handleSubmit(onSave)} id={FORMID_SELLINGPRICES}/>
      <FormProvider {...methods}>
         <ReactHookDirtyDetector/>
         {conditions.length>0 && <PageContent>
            <BodyText type={"subtitle1"}>Stock prices will automatically be calculated based on their conditions. The
               following settings determines how the stockitem will be valued based on a percentage of the
               recommended sales price.
            </BodyText>
            <br/>
            <div style={{marginTop: '20px'}}>
               <Grid container gap={1} justifyContent={'flex-start'} wrap={'nowrap'}>
                  <Grid item xs={4} container justifyContent={"start"} alignItems={"center"} style={{padding: '10px'}}>
                     <BodyText type={"body1"}>
                        Default selling prices
                     </BodyText><br/>
                  </Grid>
                  {conditions.map((cond, index) => {
                     return <Grid key={'sp_condition_' + cond.condition} item xs={2}>
                        <FormInputNumber InputProps={{
                           endAdornment: <div style={{
                              backgroundColor: '#f0f0f0',
                              padding: '12px',
                              marginRight: '-11px'
                           }}>%</div>
                        }} name={`sellPrice.${index}.pct`} control={control}
                                         label={cond.condition + ' (' + cond.name + ')'}
                                         errorMessage={'test'}/>
                     </Grid>
                  })}
                  <Grid item xs={1}>
                  </Grid>
               </Grid>

               {fields.map((field, i) => {
                  let label = "Selling price for products with ";
                  let options: string[];
                  if (field.type === EnumBrandSettingSellPriceAdvancedType.tag) {
                     label += ' TAG:'
                     options = tags
                  } else if (field.type === EnumBrandSettingSellPriceAdvancedType.productType) {
                     label += ' PRODUCT TYPE:'
                     options = productTypes
                  } else if (field.type === EnumBrandSettingSellPriceAdvancedType.source) {
                     label += ' SOURCE:'
                     options = Object.values(ItemSourceType)
                  } else {
                     label += ' UNKOWN:'
                     options = []
                  }

                  return <Grid key={'sp_' + field.id} container gap={1} justifyContent={'flex-start'} wrap={'nowrap'}>
                     <Grid item xs={4}>
                        <FormInputFree name={`sellPriceAdvanced.${i}.value`} options={options} label={label}/>
                     </Grid>
                     {conditions.map((cond, index) => {
                        return <Grid key={'sp_adv_' + field.id + '_' + cond.condition} item xs={2}>
                           <FormInputNumber InputProps={{
                              endAdornment: <div style={{
                                 backgroundColor: '#f0f0f0',
                                 padding: '12px',
                                 marginRight: '-11px'
                              }}>%</div>
                           }} name={`sellPriceAdvanced.${i}.sellPrice.${index}.pct`} control={control}
                                            label={cond.condition + ' (' + cond.name + ')'}
                                            errorMessage={'test'}/>
                        </Grid>
                     })}
                     <Grid item xs={1}>
                        <IconButton onClick={() => remove(i)}>
                           <DeleteForever/>
                        </IconButton>
                     </Grid>
                  </Grid>
               })}
               <Grid container style={{marginBottom: '20px'}}>
                  <Grid item xs={12}>
                     <Button
                        style={{marginLeft: 0}}
                        onClick={handleClickSp}
                        size="small"
                        sx={{ml: 2}}
                        aria-controls={'account-menu'}
                        aria-haspopup="true"
                        aria-expanded={'true'}
                        startIcon={<AddCircle/>}
                        variant={"outlined"}
                        color={"secondary"}
                     >
                        Add
                     </Button>
                     <Menu
                        anchorEl={anchorElSp}
                        id="account-menu"
                        open={openSp}
                        onClose={handleCloseSp}
                        onClick={handleCloseSp}
                     >
                        <Box>
                           <TitleText style={{
                              paddingLeft: '18px',
                              paddingTop: '6px',
                              paddingBottom: '5px',
                              paddingRight: '18px'
                           }}
                                      type={"subtitle2"}>
                              Advanced selling price rule for:
                           </TitleText>
                        </Box>
                        {Object.values(EnumBrandSettingSellPriceAdvancedType).map(key => {
                           return <MenuItem key={'productType_' + key} onClick={() => append({
                              value: '',
                              type: key as EnumBrandSettingSellPriceAdvancedType,
                              sellPrice: getValues('sellPrice')!.map(price => {
                                 return {
                                    pct: price!.pct,
                                    condition: price!.condition
                                 }
                              })
                           })}>
                              <ListItemIcon>
                                 <LanguageIcon fontSize="small"/>
                              </ListItemIcon>
                              {key === 'productType' && <div>Product type</div>}
                              {key === 'tag' && <div>Tag</div>}
                              {key === 'source' && <div>Source type</div>}
                           </MenuItem>
                        })}
                     </Menu>
                  </Grid>
               </Grid>
            </div>
         </PageContent>}

         <PageContent>
            <BodyText type={"subtitle1"}>
               List prices are by default the recommended retail prices (RRP) for the stock items. You can disable this,
               and control the list
               prices through campaigns.
            </BodyText>
            <Grid container gap={1} justifyContent={'flex-start'} wrap={'nowrap'}>
               <Grid item xs={4} container justifyContent={"start"} alignItems={"center"} style={{padding: '10px'}}>
                  <BodyText type={"body1"}>
                     <FormInputCheckbox name={"disableRRPListPrice"} label={"Disable RRP list prices"}/>
                  </BodyText><br/>
               </Grid>
            </Grid>
         </PageContent>
      </FormProvider>
   </div>
}
