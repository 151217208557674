import {useNavigate, useParams} from "react-router-dom";
import {
   EnumIntegrationConnectorRefType,
   RefIntegrationConfigDocument,
   useKlaviyoInstallMutation,
   useKlaviyoInstancesQuery
} from "../../../Queries";
import {Button, LinearProgress} from "@mui/material";
import Page from "../../../layout/Page";
import PageContent from "../../../layout/PageContent";
import * as React from "react";
import SettingsHeader from "../SettingsHeader";
import {ShopTabs} from "../ShopTabs";
import {RefIntegrationSettings} from "../details/RefIntegrationSettings";
import {SavingUI} from "../../../SavingProvider";

const KlaviyoPagePath = (brandId: string, edit: string) => {
   return "/brands/" + brandId + "/settings/klaviyo/" + edit
}


export const KlaviyoPage = () => {
   const {brandHouseId, edit} = useParams<{ brandHouseId: string, edit: string }>();
   const navigate = useNavigate();

   const handleChange = (newValue: string) => {
      navigate(KlaviyoPagePath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Klaviyo settings"}
                      subtitle={"Enable the klaviyo configuration to handle marketing from klaviyo"}/>
      <ShopTabs
         hideDefault={true}
         activeId={edit}
         brandHouseId={brandHouseId}
         onChange={handleChange}
         renderDefault={() => <div/>}
         renderShop={(shop) => <KlaviyoPageInner shopId={shop}/>}
      />
   </div>
}

type KlaviyoPageInnerProps = {
   shopId?: string
}

const KlaviyoPageInner = ({shopId}: KlaviyoPageInnerProps) => {
   const {data, refetch} = useKlaviyoInstancesQuery({
      variables: {
         refId: shopId!
      },
      fetchPolicy: "no-cache"
   })

   const [install] = useKlaviyoInstallMutation()

   const onInstall = async () => {
      await SavingUI.process(async () => {
         await install({
            variables: {
               refId: shopId!,
               refType: EnumIntegrationConnectorRefType.SHOP
            },
            refetchQueries: [{
               query: RefIntegrationConfigDocument,
               variables: {
                  refId: shopId!,
                  refType: EnumIntegrationConnectorRefType.SHOP
               }
            }]
         });
         await refetch()
      }, 'Klaviyo enabled', 'Installing klaviyo, please wait')
   }

   if (!data) {
      return <LinearProgress/>
   }
   const instance = data.IntegrationInstances?.[0];

   return <Page>
      <PageContent>
         {data.IntegrationInstances.length === 0 && <Button onClick={onInstall}>Install</Button>}
         {data.IntegrationInstances.length > 0 &&
            <RefIntegrationSettings settingsMode={true} instanceId={instance._id}/>
         }
      </PageContent>
   </Page>
}
