import * as React from "react";
import {useParams} from "react-router";
import {ParamBrandHouse} from "../../../Parameters";
import {RefIntegrationSettings} from "./RefIntegrationSettings";
import {DetailsPagePath} from "./DetailsPage";

export const BrandIntegrationSettingsPath = (brandHouseId: string, instanceId: string) => {
   return DetailsPagePath(brandHouseId) + '/integrations/' + instanceId
}

export const BrandIntegrationSettings = () => {
   const {instanceId} = useParams<ParamBrandHouse & { instanceId: string }>()
   return <RefIntegrationSettings instanceId={instanceId}/>
}
