import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {IntegrationConnectorFields} from "../../../Queries";
import {SimpleSelect} from "../../../components/SimpleSelect";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";
import {FormInputNumber} from "../../shop/wholesale/form/FormInputNumber";
import {FormInputSecret} from "../../shop/wholesale/form/FormInputSecret";
import {FormInputJSON} from "../../shop/wholesale/form/FormInputJSON";
import {FormInputColor} from "../../shop/wholesale/form/FormInputColor";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import {Box, Button, Grid, IconButton} from "@mui/material";
import {BodyText, TitleText} from "../../../layout/Typography";
import {DeleteForever} from "@mui/icons-material";
import * as React from "react";
import {SettingsEditorProps, SettingsFormData, SettingsLine} from "./RefIntegrationSettingsType";

export const SettingsEditor = ({settings, fields, onSave}: SettingsEditorProps) => {
   const methods = useForm<SettingsFormData>({
      defaultValues: {
         settings: expand(settings, fields)
      }
   });

   const {control, handleSubmit, setValue, watch} = methods;

   const {fields: sets, append, remove} = useFieldArray({
      control,
      name: 'settings'
   });

   console.log(fields);

   const onSettingsSave = async (data: SettingsFormData) => {
      await onSave(data.settings)
   }

   const settingNamespaces = new Map<string, SettingsLine[]>()
   const other: SettingsLine[] = [];
   for (const set of sets) {
      let field = fields.find(field => field.field === set.key)?.namespace;
      if (!field) {
         other.push(set);
      } else {
         const lines = settingNamespaces.get(field) || []
         lines.push(set);
         settingNamespaces.set(field, lines)
      }
   }

   const headings = Array.from(settingNamespaces.keys()).sort()


   const renderField = (field: IntegrationConnectorFields, index: number) => {
      const fieldName = `settings[${index}].value`;
      const label = field?.field || 'Value';
      const fieldValue = watch(fieldName as any);

      if ((field?.options || []).length > 0) {
         return <SimpleSelect
            label={field.description}
            value={JSON.stringify(fieldValue)}
            options={field.options!.map(option => {
               return {
                  id: JSON.stringify(option!.value),
                  name: option!.label
               }
            })}
            onSelectChange={(e) => {
               setValue(fieldName as any, JSON.parse(e.target.value));
            }}
         />
      } else if (field?.type === 'checkbox') {
         return <FormInputCheckbox name={fieldName} label={label}/>
      } else if (field?.type === 'number') {
         return <FormInputNumber name={fieldName} label={label}/>
      } else if (field?.type === 'password') {
         return <FormInputSecret name={fieldName} label={label}/>
      } else if (field?.type === 'json') {
         return <FormInputJSON height={"300px"} name={fieldName} placeholder={label}/>
      } else if (field?.type === 'color') {
         return <FormInputColor name={fieldName} label={label}/>
      } else if (field?.type === 'textarea') {
         return <FormInputText rows={4} multiline={true} name={fieldName} label={label} minRows={4}/>
      } else {
         return <FormInputText name={fieldName} label={label}/>
      }
   }

   const renderSettings = (lines: SettingsLine[]) => {
      return lines.map(setting => {
         const i = sets.findIndex(set => set === setting);
         const field = fields.find(field => field.field === setting.key)
         return <Grid key={"setting_" + setting.key} container spacing={1}>
            <Grid item xs={3}>
               {field?.description &&
                  <BodyText type={"subtitle2"} sx={{marginTop: '3px'}}>
                     {field?.description}
                  </BodyText>
               }
               {!field?.description &&
                  <FormInputText
                     placeholder={field?.description}
                     name={`settings[${i}].key`}
                     label={"Key"}/>
               }
            </Grid>
            <Grid item xs={8}>
               {renderField(field!, i)}
            </Grid>
            <Grid item xs={1}>
               <IconButton onClick={() => remove(i)}>
                  <DeleteForever/>
               </IconButton>
            </Grid>
         </Grid>
      })
   }

   return <FormProvider {...methods}>
      {sets.length === 0 &&
         <Grid container>
            <Grid item xs={12}>
               No settings
            </Grid>
         </Grid>
      }
      {headings.map(heading => {
         const relevantFields = settingNamespaces.get(heading) || [];
         return <Box key={"int_header_" + heading}>
            <TitleText type={"h2"}>{heading}</TitleText>
            {renderSettings(relevantFields)}
         </Box>
      })}
      {other.length > 0 && <Box>
         <TitleText type={"h2"}>Other settings</TitleText>
         {renderSettings(other)}
      </Box>}
      <Grid container spacing={1}>
         <Grid item>
            <Button onClick={handleSubmit(onSettingsSave)} variant={"contained"}>Save</Button>
         </Grid>
         <Grid item>
            <Button onClick={() => {
               append({
                  key: '',
                  value: ''
               })
            }} variant={"outlined"}>
               Add setting
            </Button>
         </Grid>
      </Grid>
   </FormProvider>
}


const expand = (settings: SettingsLine[], fields: IntegrationConnectorFields[]) => {
   const result: SettingsLine[] = [...settings];
   for (const field of fields) {
      const exist = result.find(line => line.key === field.field)
      if (!exist) {
         result.push({
            key: field.field,
            value: ''
         })
      }
   }
   return result;
}

