import * as React from "react";
import {useParams} from "react-router";
import {LogisticsIntegrationsPagePath} from "./LogisticsIntegrationsPage";
import {ParamLogisticsPartner} from "../../Parameters";
import {RefIntegrationSettings} from "../settings/details/RefIntegrationSettings";

export const LogisticsIntegrationPagePath = (lpId: string, instanceId: string) => {
   return LogisticsIntegrationsPagePath(lpId) + '/' + instanceId
}

export const LogisticsIntegrationPage = () => {
   const {instanceId} = useParams<ParamLogisticsPartner & { instanceId: string }>()
   return <RefIntegrationSettings instanceId={instanceId}/>
}
